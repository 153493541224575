import React, { Component } from 'react';
import { Link } from "gatsby"
// import Img from "gatsby-image" 

class Blogpreview extends Component {
    
    render(){
        const MAX_LENGTH = 100;   
        const MAX_LENGTH_Title = 80;   
        const {blog} = this.props   
    
        return(
            <article className="blog-listing" key={blog.slug}>
                <div className="entry-meta-content">
                  <div className="entry-media">
                    {/* <Img fluid={blog.blogImage.fluid} backgroundColor={"#f4f8fb"} /> */}
                    <img src={blog.urlToImage} alt={blog.title} width="100%" height="280px" />
                  </div>
                  <h2 className="entry-title">
                    <Link to={blog.slug}>
                      {blog.title > MAX_LENGTH ? ( blog.title) : ( blog.title.substring(0, MAX_LENGTH_Title))}
                    </Link>
                  </h2>
                  
                  <div className="entry-content" style={{height: '73px'}}>
                    {blog.description && blog.description !== '' && blog.description !== null ?
                      (blog.description > MAX_LENGTH ?
                        (blog.description) : ( blog.description.substring(0, MAX_LENGTH)+ '...'))
                      : <p><em>Nenhum dado foi fornecido</em></p>
                    }
                  </div>                  
                </div>
              
                <div className="entry-content-bottom">                      
                  <Link to={`/blog/${blog.slug}`} className="entry-read-more">
                    <span />
                    Ler mais
                  </Link>
                </div>
            </article>
        )
    }

}

export default Blogpreview


